<template>
 <div>
  <sdPageHeader title="Role">
  </sdPageHeader>
  <Main v-if="errorRole">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/role')">
       Naar roles
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-if="!errorRole">
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Configuratie">
      <a-form :model="form" :rules="rules" layout="vertical" @finish="UpdateRole">
       <a-row :gutter="30">
        <a-col :sm="12" :xs="24" class="mb-25">
         <a-form-item label="Naam" name="name">
          <a-input v-model:value="form.name" :disabled="true" placeholder=""/>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row :gutter="30">
        <a-col :sm="12" :xs="12" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Orderscope"
                      name="orderScope">
          <a-select v-model:value="form.orderScope"
                    :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in scopeOptions" :key="index"
                            :value="item.value" name="type">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="12" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Userscope"
                      name="userScope">
          <a-select v-model:value="form.userScope"
                    :allowClear="true" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in scopeOptions" :key="index"
                            :value="item.value" name="type">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>

       </a-row>
       <a-row :gutter="30" class="mb-25">
        <a-col :xs="24" class="mb-25">
         <a-form-item label="Permissies">
          <a-select v-model:value="form.permissions"
                    :allowClear="true"
                    :autoClearSearchValue="false"
                    class="sDash_fullwidth-select" maxTagCount="responsive"
                    mode="multiple"
                    size="large"
          >
           <a-select-option v-for="(item,index) in allPermissions" :key="index"
                            :value="item" name="permissions">
            {{ item }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row :gutter="30" class="mb-25">
        <a-col :xs="24" class="mb-25">
         <a-form-item label="Toegestane rollen voor toewijzing">
          <a-select v-model:value="form.allowedRoleIDs"
                    :allowClear="true"
                    :autoClearSearchValue="false"
                    class="sDash_fullwidth-select"
                    maxTagCount="responsive"
                    mode="multiple"
                    size="large"
          >
           <a-select-option v-for="(item,index) in roles" :key="index"
                            :value="item.id" name="allowedRoleIDs ">
            {{ item.name }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row :gutter="30" class="mb-25">
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                      label="Tijdlimiet"
                      name="hasTimeLimit">
          <a-select v-model:value="form.hasTimeLimit"
                    :allowClear="true"
                    :disabled="!$can('update', 'role')"
                    class="sDash_fullwidth-select"
                    size="large"
                    @change="form.allowedTimeFrom = '';form.allowedTimeTill = ''"
          >
           <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                            :value="item.value" name="hasTimeLimit">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item
          :rules="form.hasTimeLimit ? {required: true,message: 'Dit veld is verplicht'} :{required: false,message: 'Dit veld is verplicht'}"
          label="Starttijd">
          <a-time-picker
           v-model:value="form.allowedTimeFrom"
           :disabled="!form.hasTimeLimit||!$can('update', 'role')"
           format="HH:mm"
           name="allowedTimeFrom"
           style="width:100%"
           valueFormat="HH:mm:ss"
          />
         </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item
          :rules="form.hasTimeLimit ? {required: true,message: 'Dit veld is verplicht'} :{required: false,message: 'Dit veld is verplicht'}"
          label="Eindtijd">
          <a-time-picker
           v-model:value="form.allowedTimeTill"
           :disabled="!form.hasTimeLimit||!$can('update', 'role')"
           format="HH:mm"
           name="allowedTimeTill"
           style="width:100%"
           valueFormat="HH:mm:ss"
          />
         </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                      label="Daglimiet"
                      name="hasDayLimit">
          <a-select v-model:value="form.hasDayLimit"
                    :allowClear="true"
                    :disabled="!$can('update', 'role')"
                    class="sDash_fullwidth-select"
                    size="large"
                    @change="form.allowedDays = []"
          >
           <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                            :value="item.value" name="hasDayLimit">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="16" :xs="24" class="mb-25">
         <a-form-item
          :rules="form.hasDayLimit ? {required: true,message: 'Dit veld is verplicht'} :{required: false,message: 'Dit veld is verplicht'}"
          label="Inlogdagen">
          <a-select v-model:value="form.allowedDays"
                    :allowClear="true"
                    :autoClearSearchValue="false"
                    :disabled="!form.hasDayLimit||!$can('update', 'role')"
                    class="sDash_fullwidth-select"
                    maxTagCount="responsive"
                    mode="multiple"
                    size="large"
          >
           <a-select-option v-for="(item,index) in dayOptions" :key="index"
                            :value="item.key" name="allowedDays">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                      label="Orderinzage beperken na periode"
                      name="hasOrderViewDayLimit">
          <a-select v-model:value="form.hasOrderViewDayLimit"
                    :allowClear="true"
                    :disabled="!$can('update', 'role')"
                    class="sDash_fullwidth-select"
                    size="large"
                    @change="form.orderViewLimitDays = null"
          >
           <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                            :value="item.value" name="hasDayLimit">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Aantal dagen"
                      name="orderViewLimitDays">
          <a-input v-model:value="form.orderViewLimitDays"
                   :addon-after="!form.orderViewLimitDays || form.orderViewLimitDays <= 1  ? 'dag' : 'dagen'"
                   :disabled="!form.hasOrderViewDayLimit||!$can('update', 'role')"
                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                   placeholder=""
                   type="number"/>
         </a-form-item>
        </a-col>
         </a-row>
        <a-row :gutter="30" class="mb-25">
         <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Accounts deactiveren na login-inactiviteit"
                        name="hasUserLoginInactivityLimit">
             <a-select v-model:value="form.hasUserLoginInactivityLimit"
                       :allowClear="true"
                       :disabled="!$can('update', 'role')"
                       class="sDash_fullwidth-select"
                       size="large"
                       @change="form.userLoginInactivityDays = null"
             >
               <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                :value="item.value" name="hasUserLoginInactivityLimit">
                 {{ item.key }}
               </a-select-option>
             </a-select>
           </a-form-item>
         </a-col>
         <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Aantal dagen"
                        name="userLoginInactivityDays">
             <a-input v-model:value="form.userLoginInactivityDays"
                      :addon-after="!form.userLoginInactivityDays || form.userLoginInactivityDays <= 1  ? 'dag' : 'dagen'"
                      :disabled="!form.hasUserLoginInactivityLimit||!$can('update', 'role')"
                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""
                      type="number"/>
           </a-form-item>
         </a-col>
       </a-row>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button v-if="$can('update', 'role')" :disabled="loadingPatchRole" :loading="loadingPatchRole"
                    class="btn-signin" html-type="submit" size="large" type="primary">Opslaan
          </a-button>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_form-action">
       <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/role')">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, Main} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {dayOptions, defaultOptions} from "@/utility/enums";
import moment from "moment";

export default defineComponent({
 name: 'RoleEdit',
 components: {
  BasicFormWrapper,
  Main,
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const form = reactive(computed(() => state.role.role));
  const roles = computed(() => state.role.roles);
  const errorRole = computed(() => state.role.errorRole);
  const loadingPatchRole = computed(() => state.role.loadingPatchRole);
  const allPermissions = computed(() => state.role.allPermissions);
  const scopeOptions = [
   {
    value: 'ALL',
    key: 'Alle records',
   },
   {
    value: 'ORGANIZATION',
    key: 'Alleen organisatie records',
   },
   {
    value: 'TEAM',
    key: 'Alleen team records',
   },
   {
    value: 'SELF',
    key: 'Alleen eigen records',
   },
  ]
  const rules = {
   name: [
    {
     required: true,
     message: 'Please enter user name',
    },
   ],
   description: [
    {
     required: true,
     message: 'Please enter user name',
    },
   ],
  };
  const UpdateRole = () => {
   dispatch('patchRole', {
    id: params.id,
    permissions: form.value.permissions,
    allowedRoleIDs: form.value.allowedRoleIDs,
    hasDayLimit: form.value.hasDayLimit,
    hasTimeLimit: form.value.hasTimeLimit,
    allowedTimeFrom: form.value.allowedTimeFrom && form.value.allowedTimeFrom.length > 5 ? form.value.allowedTimeFrom.substring(0, 5) : form.value.allowedTimeFrom,
    allowedTimeTill: form.value.allowedTimeTill && form.value.allowedTimeTill.length > 5 ? form.value.allowedTimeTill.substring(0, 5) : form.value.allowedTimeTill,
    allowedDays: form.value.allowedDays,
    hasOrderViewDayLimit: form.value.hasOrderViewDayLimit,
    orderViewLimitDays: form.value.orderViewLimitDays,
     hasUserLoginInactivityLimit: form.value.hasUserLoginInactivityLimit,
     userLoginInactivityDays: form.value.userLoginInactivityDays,
    scopes: [{
     subject: "user",
     scope: form.value.userScope,
    },
     {
      subject: "order",
      scope: form.value.orderScope,
     }]
   });
  };

  const UpdateRole2 = () => {
   dispatch('patchRole', {
    id: params.id,
    hasDayLimit: form.value.hasDayLimit,
    hasTimeLimit: form.value.hasTimeLimit,
    allowedTimeFrom: form.value.allowedTimeFrom && form.value.allowedTimeFrom.length > 5 ? form.value.allowedTimeFrom.substring(0, 5) : form.value.allowedTimeFrom,
    allowedTimeTill: form.value.allowedTimeTill && form.value.allowedTimeTill.length > 5 ? form.value.allowedTimeTill.substring(0, 5) : form.value.allowedTimeTill,
    allowedDays: form.value.allowedDays,
    hasOrderViewDayLimit: form.value.hasOrderViewDayLimit,
    orderViewLimitDays: form.value.orderViewLimitDays,
     hasUserLoginInactivityLimit: form.value.hasUserLoginInactivityLimit,
     userLoginInactivityDays: form.value.userLoginInactivityDays,
   });
  };


  onMounted(() => {
   dispatch('getRoles', {pageSize: 100});
   dispatch('getRolePermissions')
   dispatch('getRole', params.id);
  });

  return {
   form,
   rules,
   errorRole,
   UpdateRole,
   UpdateRole2,
   scopeOptions,
   allPermissions,
   roles,
   dayOptions,
   defaultOptions,
   moment,
   loadingPatchRole,
  };
 },
});
</script>
