<template>
  <div>
    <sdPageHeader title="Systeemtaak">
    </sdPageHeader>
    <Main v-if="errorScheduledTask">
      <sdCards title="">
        <a-result status="warning" title="Er is een fout opgetreden bij het ophalen van de gegevens."
                  style="padding: 0">
          <template #extra>
            <Button class="ant-btn ant-btn-primary" type="primary" size="large" @click="$router.replace('/scheduledTask')">
              Naar Systeemtaken
            </Button>
          </template>
        </a-result>
      </sdCards>
    </Main>
    <Main v-if="!errorScheduledTask">
      <div class="user-info-form">
        <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
            <a-form :model="form" :rules="rules" layout="vertical" @finish="UpdateScheduledTask">
              <a-row :gutter="30">
                <a-col :sm="12" :xs="12" class="mb-25">
                  <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Taaktype"
                               name="type">
                    <a-select v-model:value="form.type"
                              :allowClear="true" class="sDash_fullwidth-select"
                              size="large">
                      <a-select-option v-for="(item,index) in typeOptions" :key="index"
                                       :value="item.value" name="type">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :sm="12" :xs="12" class="mb-25">
                  <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="App"
                               name="appType">
                    <a-select v-model:value="form.appType"
                              :allowClear="true" class="sDash_fullwidth-select"
                              size="large">
                      <a-select-option v-for="(item,index) in appTypeOptions" :key="index"
                                       :value="item.value" name="appType">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row :gutter="30">
                <a-col :sm="12" :xs="12" class="mb-25">
                  <a-form-item label="Parameters" name="parameters">
                    <a-input  v-model:value="form.parameters" placeholder=""/>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :sm="12" :xs="24">
                  <div class="sDash_form-action">
                    <sdButton v-if="$can('update', 'scheduledTask')" class="btn-signin" type="primary"> Opslaan</sdButton>
                  </div>
                </a-col>
              </a-row>

            </a-form>
              </sdCards>
          <sdCards title="Uitvoerlogica">
            <a-form :model="form" :rules="rules" layout="vertical" @finish="UpdateScheduledTask">
              <a-row :gutter="30" class="mb-25">
                <a-col :sm="24" :xs="24" class="mb-25">
                  <a-form-item label="Expressie editor">
                    <cron-ant
                        v-model="form.cronExpression"
                        :button-props="{ type: 'primary', style: {backgroundColor: '#5a5f7d'} }"
                         />
                  </a-form-item>
                </a-col>
                <a-col :sm="3" :xs="12" class="mb-25">
                  <a-form-item label="Expressie" name="cronExpression">
                    <a-input :disabled="true" v-model:value="form.cronExpression" placeholder=""/>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :sm="12" :xs="24">
                  <div class="sDash_form-action">
                    <sdButton v-if="$can('update', 'scheduledTask')" class="btn-signin" type="primary"> Opslaan</sdButton>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </sdCards>
        </BasicFormWrapper>
      </div>
      <sdCards>
        <a-row>
          <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/scheduledTask')">
                Terug
              </sdButton>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import {BasicFormWrapper, Main} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'ScheduledTaskEdit',
  components: {
    BasicFormWrapper,
    Main,
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const form = reactive(computed(() => state.scheduledTask.scheduledTask));
    const errorScheduledTask = computed(() => state.scheduledTask.errorScheduledTask);
    const rules = {
      name: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      text: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
    };
    const UpdateScheduledTask = () => {
      dispatch('patchScheduledTask', {
        id: params.id,
        cronExpression: form.value.cronExpression,
        parameters: form.value.parameters,
        type: form.value.type,
        appType: form.value.appType,
      });
    };

    const appTypeOptions = [
      {
        value: 'VALADI',
        key: 'DGB',
      },
      {
        value: 'HEM',
        key: 'HEM',
      },
      {
        value: 'HEMLEAD',
        key: 'HEM Lead',
      }
    ]

    const typeOptions = [
      {
        value: 'SENDORDERS',
        key: 'Orderverzending',
      },
      {
        value: 'SYNCTARIFF',
        key: 'Tarief synchronisatie',
      },
      {
        value: 'SYNCSTATUSSES',
        key: 'Status synchronisatie',
      },
      {
        value: 'SENDFILES',
        key: 'Orderbestand verzending',
      }
    ]

    onMounted(() => {
      dispatch('getScheduledTask', params.id);
    });

    return {
      typeOptions,
      appTypeOptions,
      form,
      rules,
      errorScheduledTask,
      UpdateScheduledTask,
    };
  },
});
</script>
